
import * as cloudflareRuntime$RGM9Gphhhl from '/var/www/fictionme-web/html/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as cloudinaryRuntime$mcycLBHptJ from '/var/www/fictionme-web/html/node_modules/@nuxt/image/dist/runtime/providers/cloudinary'
import * as ipxRuntime$vpwcKVdD5W from '/var/www/fictionme-web/html/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "d1januq98nfr6g.cloudfront.net",
    "fictionme.s3.us-east-2.amazonaws.com",
    "fictionme-dev.s3.us-east-2.amazonaws.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$RGM9Gphhhl, defaults: {"baseURL":"https://fictionme.net/"} },
  ['cloudinary']: { provider: cloudinaryRuntime$mcycLBHptJ, defaults: {"baseURL":"https://res.cloudinary.com/dmmkcla2e/image/upload/public/"} },
  ['ipx']: { provider: ipxRuntime$vpwcKVdD5W, defaults: {} }
}
        